<template>
  <div class="mt-1 mx-3">
    <payment-references> </payment-references>
  </div>
</template>

<script>
import PaymentReferences from "@/components/accounting/accounting-agency/payment-references/index.vue";
import { mapActions } from "vuex";

export default {
  components: {
    PaymentReferences,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
};
</script>
