<template>
  <div class="mt-1 mx-3">
    <payment-references-create> </payment-references-create>
  </div>
</template>

<script>
import PaymentReferencesCreate from "@/components/accounting/accounting-agency/payment-references/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    PaymentReferencesCreate,
  },
  created() {
    this.notifyQueue({
      text: "Crea Causale",
      path: "payment_references.create",
      level: 4,
    });
  },
};
</script>
