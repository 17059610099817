<template>
  <div class="mt-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                name="cod_causale"
                type="text"
                label="Codice Causale"
                v-model="filter.byAttribute.code"
                placeholder="Inserisci un codice causale"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="descrizione"
                type="text"
                label="Descrizione"
                v-model="filter.byAttribute.caption"
                placeholder="Inserisci una descrizione"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <!-- <button-group-table></button-group-table> -->

    <payment-references-table
      @edit="onEdit"
      @destroy="onDestroy"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :onlyActions="['edit', 'destroy', 'infomodal']"
      :filterName="filterName"
      :ref="tableRef"
    ></payment-references-table>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import PaymentReferencesTable from "@/components/tables/PaymentReferences";
import BaseInput from "@/components/form/BaseInput";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import { mapActions } from "vuex";
export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterPaymentReference",
      tableRef: "PaymentReferenceTableRef",
      resource: "payment_references",
      repository: "payment_reference",
      fields: [
        {
          key: "code",
          label: this.getDictionary("code", "payment_reference"),
          sortable: true,
        },
        {
          key: "caption",
          label: this.getDictionary("caption", "payment_reference"),
          sortable: true,
        },
      ],
    };
  },
  components: {
    BaseInput,
    // ButtonGroupTable,
    PaymentReferencesTable,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          code: null,
          caption: null,
        },
      };
      return init;
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create` });
    },

    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${id}` },
      });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$store.dispatch("auth/payment_references");
          this.$showSnackbar({
            preset: "success",
            text: "Causale Eliminata con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
  },
};
</script>
